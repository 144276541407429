//FontAwesmoe icons
$fa-font-path: '../../../fonts';
@import '../../node_modules/font-awesome/scss/font-awesome.scss';

//Bootstrap
@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

//Colors
$light-grey: #eeeeee;
$dark-grey: #1f1f1f;
$white: #ffffff;

//Prefix for no-conflict
$prefix: 'BDDE-';

body{
    font-family: 'Open Sans';
    background-color: $light-grey;
}

.edited-area{
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: $white;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
    height: 1655px;
    padding: $grid-gutter-width*2;
    color: $dark-grey;
    .component{
        clear: both;
        display: block;
        margin-bottom: 10px;
        position: relative;
        border: 1px dashed rgba(0,0,0,0.1);

        &:hover{
            .toolbar{
                display: block;
            }
        }
        .format-toolbar{
            display: block;
            position: absolute;
            bottom: 100%;
            left: 0;
        }

        *:focus{
            outline: none;
            box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
        }

        &.active{

        }

        &.header{
            margin-top: $grid-gutter-width/2;
        }
        img{
            max-width: 100%;
        }
        &.division{
            clear: none;
            display: table;
            padding-right:0;
            padding-left:0;
            .format-toolbar{
                .text-formatting-tools{
                    display: none;
                }
            }
        }
    }
}

.components-toolbar{ 
    position: fixed;
    top: 100px;
    z-index: 100;
    left: 20px;
    min-width: 300px;
    .panel-move-handle{
        cursor: move;
    }
    .nav-tabs{
        li{
            a{
                margin: 0;
                color: #333;
                background-color: #eee;
            }
            &.active{
                a{
                    background-color: #fff;
                    color: $brand-primary;
                }
            }
        }
    }
    .list-group{
        margin: 0;
        .list-group-item{
            cursor: move;
            &:hover{
                background-color: #eee;
            }
        }
    }
    .tab-content{
        margin: 0;
    }
}

.format-toolbar{
    display: none;
}